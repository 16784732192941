import React, { useState, useEffect } from "react";
import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';

const InstallPwa = () => {
  const [isIos, setIsIos] = useState(false);
  const [isInStandaloneMode, setIsInStandaloneMode] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handler = (e) => {
      // Предотвращаем автоматическое появление баннера установки
      e.preventDefault();
      // Сохраняем событие для последующего использования
      setDeferredPrompt(e);
      // Показываем кнопку установки
      setIsVisible(true);
    };

    // Слушаем событие `beforeinstallprompt`
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const installApp = async () => {
    if (isIos) {
      navigate('/install-ios');
      return;
    }

    if (!deferredPrompt) return;

    // Показываем встроенный диалог установки
    deferredPrompt.prompt();

    // Ждём ответа пользователя
    const choiceResult = await deferredPrompt.userChoice;
    if (choiceResult.outcome === "accepted") {
      console.log("Пользователь установил приложение");
    } else {
      console.log("Пользователь отклонил установку");
    }

    // Очищаем сохранённое событие
    setDeferredPrompt(null);
    setIsVisible(false);
  };

  useEffect(() => {
    const handleAppInstalled = () => {
      console.log("Приложение установлено");
      setIsVisible(false);
    };

    window.addEventListener("appinstalled", handleAppInstalled);

    return () => window.removeEventListener("appinstalled", handleAppInstalled);
  }, []);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const ios = /iphone|ipad|ipod/.test(userAgent);
    const inStandaloneMode = window.matchMedia('(display-mode: standalone)').matches;

    setIsIos(ios);
    setIsInStandaloneMode(inStandaloneMode);
  }, []);

  if (!isVisible && (!isIos || isInStandaloneMode)) {
    return null;
  }

  return (
    <Paper>
      <Stack direction={'row'} sx={{ p: 1 }} spacing={1} alignItems={"center"}>
        <Box
          component="img"
          src="/apple-touch-icon.png"
          alt="logo"
          width={48}
          height={48}
        />
        <Stack>
          <Typography variant="h6">ПрофиСфера</Typography>
          <Typography variant="caption">Сервис поиска мастеров с отзывами и уведомлениями</Typography>
        </Stack>
        <Box flexGrow={1} />
        <IconButton aria-label="download" size="large" color="secondary" onClick={installApp}>
          <GetAppRoundedIcon fontSize="inherit" />
        </IconButton>
      </Stack>
    </Paper>
  );
};

export default InstallPwa;
